<template>
  <div class="mb-2">
    <label
      for="input"
      class="block text-sm font-medium leading-5 text-gray-700"
      >{{ label }}</label
    >
    <div v-if="countryState && countryState.length > 0">
      <multiselect
        ref="country"
        label="label"
        track-by="label"
        key="country"
        v-model="selected"
        :multiple="true"
        :options="countryState"
        placeholder="País"
        selected-label=""
        select-label=""
        deselect-label=""
      >
        <template slot="selection" slot-scope="{ values, isOpen }">
          <span class="text-xs multiselect__single">
            {{ values.length }} paises seleccionados
          </span>
          <div v-if="!isOpen" class="flex flex-wrap items-center">
            <div
              v-for="country in values"
              :key="country.code"
              class="pr-1"
              v-tooltip="country.label"
            >
              <country-flag
                :country="country.code.toLowerCase()"
                size="small"
              />
              <span v-if="values.length === 1">{{ country.label }}</span>
            </div>
          </div>
        </template>

        <template slot="singleLabel" slot-scope="{ option }">
          <div class="flex items-center space-x-2 text-sm">
            <country-flag :country="option.code.toLowerCase()" size="normal" />
            <div class="">
              {{ option.label }}
            </div>
          </div>
        </template>
        <template slot="option" slot-scope="{ option }">
          <div v-if="option" class="flex items-center space-x-2 text-sm">
            <country-flag :country="option.code.toLowerCase()" size="normal" />
            <div class="">
              {{ option.label }}
            </div>
          </div>
        </template>
        <template slot="placeholder">
          <div class="text-xs">Seleccionar País</div>
        </template>
      </multiselect>
    </div>
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: "Paises",
    },
    description: {
      type: String,
      default: "",
    },
  },
  async fetch() {
    // const store = this.$nuxt.context.store
    // const countries = await store.dispatch('country-state/find', {})
    // this.countries = countries
    // if (this.value) {
    //   const countryObject = this.countries.find((it) => it.code === this.value)
    //   this.country = countryObject
    // }
  },
  data() {
    return {
      countries: [],
      selected: null,
      detected: null,
    };
  },
  mixins: [makeFindMixin({ service: "country-state" })], // Step 2
  computed: {
    countryStateParams() {
      return {
        paginate: false,
      };
    },
  },
  watch: {
    countryState: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          let selected = [];
          if (this.value) {
            this.value.forEach((item) => {
              let ct = newValue.find((it) => {
                return it.code === item;
              });
              if (ct) selected.push(ct);
            });
            this.selected = selected;
          }
        }
      },
    },
    selected(newValue) {
      if (newValue) {
        this.$emit(
          "input",
          newValue.map((v) => v.code)
        );
      }
    },
  },
  mounted() {},
};
</script>

<style lang="css">
.multiselect__input::placeholder {
  @apply text-sm;
}
</style>

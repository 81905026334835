import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d51a2aa2&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d51a2aa2",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WCountrySelect from '@/components/WCountrySelect.vue'
import WDateTime from '@/components/WDateTime.vue'
import WInput from '@/components/WInput.vue'
import WMoney from '@/components/WMoney.vue'
import WStateSelect from '@/components/WStateSelect.vue'
import WSwitch from '@/components/WSwitch.vue'
import WTextarea from '@/components/WTextarea.vue'
import WTopBar from '@/components/WTopBar.vue'
import WWysiwyg from '@/components/WWysiwyg.vue'
autoInstallComponents(component, {WCountrySelect,WDateTime,WInput,WMoney,WStateSelect,WSwitch,WTextarea,WTopBar,WWysiwyg})

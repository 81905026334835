<template>
  <div>
    <w-top-bar>Detalles de la Zona</w-top-bar>
    <div v-if="item" class="h-full p-5 bg-gray-200">
      <FeathersVuexFormWrapper :item="item" watch>
        <!-- <template v-slot="{ clone, save, reset, remove }"> -->
        <template v-slot="{ clone, save }">
          <div>
            <div class="flex w-full p-5 text-sm bg-white rounded-md shadow">
              <div class="w-2/5 md:pr-4">
                <div class="text-lg font-medium">Información General</div>
                <div class="text-gray-700">
                  La información se mostrara en la página de promoción del
                  Cupón.
                </div>
              </div>
              <div class="w-3/5">
                <w-input label="Nombre" v-model="clone.name" />
                <w-input label="Empresa" v-model="clone.company" />
                <div class="mt-5">
                  <w-switch
                    label="Mas Detalles"
                    v-model="item.hasExtra"
                  ></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    Agregar más detalles a la zona, como descripción e
                    instrucciones.
                  </div>
                </div>
                <w-wysiwyg
                  class="mt-2"
                  label="Detalle"
                  v-model="clone.description"
                  description="Aparece en el detalle del método, en el momento de elegir el método de envío."
                />

                <w-wysiwyg
                  v-if="item.hasExtra"
                  class="mt-2"
                  label="Instrucciones Post-Venta"
                  description="Aparece en gracias por tu compra y en el correo de confirmación de compra."
                  v-model="clone.instructions"
                />
                <!-- <w-textarea description="Texto para el banner que notifica al cliente del descuento." label="Texto del Banner" v-model="clone.bannerText" />
                <w-select
                  label="Tipo de Cupón"
                  v-model="clone.type"
                  :options="discountTypes"
                /> -->
                <div class="mt-5">
                  <w-switch
                    label="Disponible"
                    v-model="clone.isPublic"
                  ></w-switch>
                  <div class="text-sm text-gray-500 md:text-normal">
                    Si está desactivado, el método no se mostrará como opción
                    para los clientes.
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Tarifas</div>
                <div class="text-gray-700">Tarifas de la zona.</div>
              </div>
              <div class="w-3/5">
                <div class="flex flex-col py-2 space-y-2">
                  <!-- <w-money
                  v-model="clone.price.ars"
                  label="Precio en Pesos"
                  name="precio en pesos"
                  currency="ars"
                  placeholder="100 ARS"
                  class="mb-2"
                /> -->
                  <w-money
                    v-if="!clone.free"
                    v-model="clone.price.usd"
                    label="Precio en Dolares"
                    name="precio en dolares"
                    currency="uds"
                    :dolar="150"
                    placeholder="100 USD"
                    class="mb-2"
                  />
                  <w-switch
                    label="Envio Gratis"
                    v-model="clone.free"
                  ></w-switch>
                  <div>
                    <w-input label="Tiempo Mínimo" v-model="clone.minTime" />
                    <w-input label="Tiempo Máximo" v-model="clone.maxTime" />
                    <div class="mt-5">
                      <w-switch
                        label="Mismo día"
                        v-model="clone.sameDayDelivery"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Activar si la zona permite envíos en el mismo día.
                      </div>
                    </div>
                    <div class="mt-5">
                      <w-switch
                        label="Siguiente día"
                        v-model="clone.nextDayDelivery"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Activar si la zona permite envíos al día siguiente.
                      </div>
                    </div>
                    <div class="mt-5">
                      <w-switch
                        label="¿Es un local de retiro?"
                        v-model="clone.isDeposit"
                      ></w-switch>
                      <div class="text-sm text-gray-500 md:text-normal">
                        Activar si la zona es una opción de retiro por el
                        cliente.
                      </div>
                    </div>
                    <div
                      v-if="clone.sameDayDelivery || clone.nextDayDelivery"
                      class="mt-5"
                    >
                      <vue-timepicker
                        v-model="clone.dayDeliveryLimit"
                        close-on-complete
                        class="w-32"
                      ></vue-timepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Zonas</div>
                <div class="text-gray-700">
                  Paises y ciudades donde la zona es efectiva.
                </div>
              </div>
              <div class="w-3/5">
                <div class="flex flex-col py-2 space-y-2">
                  <div>
                    <w-switch
                      label="¿Es Internacional?"
                      v-model="clone.world"
                    ></w-switch>
                    <div class="text-sm text-gray-500 md:text-normal">
                      Selecciona esta opción si la zona abarca todos los paises
                      del mundo.
                    </div>
                  </div>
                  <w-country-select
                    v-if="!clone.world"
                    class="mt-5"
                    v-model="clone.countries"
                  ></w-country-select>

                  <div>
                    <w-switch
                      label="Limitar Provincia"
                      v-model="clone.limitState"
                    ></w-switch>
                    <div class="text-xs text-gray-500 md:text-normal">
                      Selecciona esta opción si la zona abarca solo una
                      provincia.
                    </div>
                  </div>
                  <w-state-select
                    v-if="oneCountry && clone.limitState"
                    :country="oneCountry"
                    class="mt-5"
                    v-model="clone.state"
                  ></w-state-select>
                  <div>
                    <div class="mt-5" v-if="!clone.world && isArgentina">
                      <w-textarea
                        label="Códigos Postales"
                        v-model="clone.zipCodes"
                      ></w-textarea>
                      <div class="text-xs text-gray-500 md:text-normal">
                        Códigos postales donde la zona de envíos es válida,
                        separados por coma (acepta rangos ####-####).
                      </div>
                    </div>
                    <!-- <div class="mt-5" v-if="!clone.world && isArgentina">
                    <w-switch
                      label="¿Es GBA?"
                      v-model="clone.gba"
                    ></w-switch>
                    <div class="text-sm text-gray-500 md:text-normal">
                      Selecciona esta opción si la zona abarca Gran Buenos Aires.
                    </div>
                  </div>
                  <div class="mt-5" v-if="!clone.world&& isArgentina">
                    <w-switch
                      label="¿Es AMBA?"
                      v-model="clone.amba"
                    ></w-switch>
                    <div class="text-sm text-gray-500 md:text-normal">
                      Selecciona esta opción si la zona abarca AMBA (Buenos Aires).
                    </div>
                  </div> -->
                    <div v-if="clone.dateLimit" class="mt-5">
                      <w-date-time
                        label-day="Fecha de Vencimiento"
                        v-model="clone.date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="flex w-full p-5 mt-5 text-sm bg-white rounded-md shadow"
            >
              <div class="w-2/5">
                <div class="text-lg font-medium">Limitar Productos</div>
                <div class="text-gray-700">
                  Habilitar el método solo para algunos productos.
                </div>
              </div>
              <div class="w-3/5">
                <div class="flex flex-col py-2 space-y-2">
                  <multiselect
                    key="products-in-cart"
                    v-model="clone.onlyProducts"
                    :options="products"
                    track-by="name"
                    label="name"
                    :searchable="false"
                    placeholder="Selecciona productos"
                    selected-label="Seleccionado"
                    select-label="Enter para seleccionar"
                    deselect-label="Enter para quitar"
                    :allow-empty="false"
                    :multiple="true"
                  ></multiselect>
                </div>
              </div>
            </div>

            <div class="flex justify-end py-2">
              <div
                v-if="saved"
                class="flex items-center text-sm font-medium text-green-600"
              >
                <div>Cambios guardados</div>
                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-green-600 transition duration-150 ease-in-out"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div
                v-if="saving"
                class="flex items-center text-sm font-medium text-gray-600"
              >
                <div>Guardando cambios</div>

                <svg
                  class="w-5 h-5 ml-1 mr-2 font-normal text-gray-600 transition duration-150 ease-in-out animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </div>
              <button
                @click="doSave(save)"
                class="flex items-center px-4 py-2 text-sm font-bold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
              >
                Guardar
              </button>
            </div>
          </div>
        </template>
      </FeathersVuexFormWrapper>
    </div>
  </div>
</template>

<script>
import { FeathersVuexFormWrapper, makeFindMixin } from "feathers-vuex";
// Main JS (in UMD format)
import VueTimepicker from "vue2-timepicker";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

// CSS
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  name: "EditShippingZone",
  components: {
    FeathersVuexFormWrapper,
    VueTimepicker,
    Multiselect,
  },
  computed: {
    oneCountry() {
      if (this.item.countries && this.item.countries.length === 1)
        return this.item.countries[0];
      return false;
    },
    id() {
      return this.$route.params.id;
    },
    // Returns a new Todo if the route `id` is 'new', or returns an existing Todo.
    item() {
      const { ShippingZone } = this.$FeathersVuex.api;

      return this.id === "new"
        ? new ShippingZone()
        : ShippingZone.getFromStore(this.id);
    },
    isArgentina() {
      if (!this.item.countries) return null;
      return this.item.countries.some((c) => c === "AR");
    },
    productsParams() {
      return {
        query: {
          // type: { $in: ["books", "magazine"] },
          $sort: {
            name: 1,
          },
          $limit: 300,
          $select: ["name", "_id", "type"],
        },
      };
    },
  },
  watch: {
    id: {
      handler(val) {
        // Early return if the route `:id` is 'new'
        if (val === "new") {
          return;
        }
        const { ShippingZone } = this.$FeathersVuex.api;
        const existingRecord = ShippingZone.getFromStore(val);

        // If the record doesn't exist, fetch it from the API server
        // The `item` getter will automatically update after the data arrives.
        if (!existingRecord) {
          ShippingZone.get(val);
        }
      },
      // We want the above handler handler to run immediately when the component is created.
      immediate: true,
    },
  },
  data() {
    return {
      hasExtra: false,
      saved: false,
      saving: false,
      discountTypes: [
        { key: "amount", label: "Monto Fijo" },
        { key: "percentage", label: "Porcentaje" },
      ],
      productTypes: [
        { key: "books", label: "Libro" },
        { key: "ebook", label: "Libro Digital" },
        { key: "magazine", label: "Revista" },
        { key: "streamings", label: "Evento Streaming" },
      ],

      deliveryTimes: [
        { key: 0, label: "En el día" },
        { key: "3-days", label: "1-3 Días" },
        { key: "1-week", label: "1 Semana" },
        { key: "2-week", label: "15 Días" },
        { key: "4-week", label: "15-30 Días" },
      ],
    };
  },
  mixins: [makeFindMixin({ service: "products" })],
  methods: {
    async doSave(save) {
      this.saved = false;
      this.saving = true;
      let saved = await save();
      this.saving = false;
      this.saved = true;
      const self = this;
      setTimeout(() => {
        self.saved = false;
      }, 3000);
      this.handleSaveReponse(saved);
    },
    handleSaveReponse(savedShippingZone) {
      // Redirect to the newly-saved item
      console.log("handleSaveReponse", savedShippingZone);
      if (this.id === "new") {
        this.$router.push({ params: { id: savedShippingZone._id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"input"}},[_vm._v(_vm._s(_vm.label))]),(_vm.countryState && _vm.countryState.length > 0)?_c('div',[_c('multiselect',{key:"country",ref:"country",attrs:{"label":"label","track-by":"label","multiple":true,"options":_vm.countryState,"placeholder":"País","selected-label":"","select-label":"","deselect-label":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var values = ref.values;
var isOpen = ref.isOpen;
return [_c('span',{staticClass:"text-xs multiselect__single"},[_vm._v(" "+_vm._s(values.length)+" paises seleccionados ")]),(!isOpen)?_c('div',{staticClass:"flex flex-wrap items-center"},_vm._l((values),function(country){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(country.label),expression:"country.label"}],key:country.code,staticClass:"pr-1"},[_c('country-flag',{attrs:{"country":country.code.toLowerCase(),"size":"small"}}),(values.length === 1)?_c('span',[_vm._v(_vm._s(country.label))]):_vm._e()],1)}),0):_vm._e()]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"flex items-center space-x-2 text-sm"},[_c('country-flag',{attrs:{"country":option.code.toLowerCase(),"size":"normal"}}),_c('div',{},[_vm._v(" "+_vm._s(option.label)+" ")])],1)]}},{key:"option",fn:function(ref){
var option = ref.option;
return [(option)?_c('div',{staticClass:"flex items-center space-x-2 text-sm"},[_c('country-flag',{attrs:{"country":option.code.toLowerCase(),"size":"normal"}}),_c('div',{},[_vm._v(" "+_vm._s(option.label)+" ")])],1):_vm._e()]}}],null,false,2847713847),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"placeholder"},[_c('div',{staticClass:"text-xs"},[_vm._v("Seleccionar País")])])],2)],1):_vm._e(),_c('div',{staticClass:"text-xs text-gray-600"},[_vm._v(_vm._s(_vm.description))])])}
var staticRenderFns = []

export { render, staticRenderFns }
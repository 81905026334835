<template>
  <div class="mb-2">
    <label
      for="input"
      class="block text-sm font-medium leading-5 text-gray-700"
      >{{ label }}</label
    >
  <div v-if="states && states.length > 0">
  <multiselect
    ref="state"
    key="state"
    v-model="selected"
    :options="states"
    track-by="label"
    label="label"
    :searchable="true"
    :tabindex="tabindex"
    placeholder="Provincia"
    selected-label=""
    select-label=""
    deselect-label=""
  >
    <template slot="singleLabel" slot-scope="{ option }">
      <span class="text-sm">
        {{ option.label }}
      </span>
    </template>
    <template slot="option" slot-scope="{ option }">
      <span class="text-sm">
        {{ option.label }}
      </span>
    </template>
  </multiselect>
  </div>
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>

</template>

<script>
import {makeFindMixin} from "feathers-vuex"
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: "Provincia",
    },
    description: {
      type: String,
      default: "",
    },
    country: {
      type: String,
      default: null,
    },
    tabindex: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
      detected: null,
    }
  },
  mixins: [
    makeFindMixin({ service: 'states', qid: "states"}),
  ], // Step 2
  computed: {
      countryCode(){
          return this.country
      },
    //   statesParams() {
    //       return {}
    //   },
    statesParams() {
      return {
        query: {
            countryCode:this.countryCode,
            state: true,
        }
      }
    }
  },  
  watch: {
    // states: {
    //   immediate: true,
    //   handler(newValue) {
    //     if (newValue) {
    //       let selected = []
    //       if(this.value){
    //         this.value.forEach(item => {
    //           let ct = newValue.find(it => {
    //             return it.code === item
    //           })
    //           if(ct)
    //           selected.push(ct)
    //         });
    //         this.selected = selected
    //       }
    //     }
    //   }
    // },
    countryCode: {
      immediate: true,
      handler(newValue) {
        console.log('country changed state')
        if (newValue) {
          this.state = null
          this.findStates()
        }
      },
    },
    selected(newValue) {
      if (newValue) {
        this.$emit("input", newValue)
      }
    },
  },
  mounted() {

  },
}
</script>

<style lang="css">
.multiselect__input::placeholder {
  @apply text-sm;
}
</style>
